import { q, qq } from 'bobjoll/ts/library/dom';
import { numberNormalizer } from 'Library/helpers';
import { Settings } from 'Settings';

export class Advertisement {
    private static isSetup = false;
    private static instances: AdvertisementInstance[] = [];
    private static classesResources = ['.showcase__item', '.showcase__fake'];
    private static classesSponsor = ['.spr'];
    private static classesRow = ['.row'];

    private static getRow(settings: SetAdvertisementSettings) {
        const sponsor = settings.sponsors[settings.sponsorIndex];

        if (sponsor && sponsor.dataset.sprRow) {
            return numberNormalizer(sponsor.dataset.sprRow);
        }

        if (settings.isDetail) {
            return 1;
        }

        if (window.innerWidth >= Settings.breakpoints.md) {
            return 2;
        } else if (window.innerWidth >= Settings.breakpoints.sm) {
            return 3;
        } else if (window.innerWidth < Settings.breakpoints.sm) {
            return 5;
        }

        return 2;
    }

    private static getInstances() {
        const garbage: number[] = [];
        const active = Advertisement.instances.filter(function(instance, index) {
            if (!instance.element.parentElement) {
                garbage.push(index);
            }

            return instance.element.parentElement;
        });

        garbage.reverse().forEach(index => Advertisement.instances.splice(index, 1));

        return active;
    }

    private static setPosition(settings: SetAdvertisementSettings, resource: HTMLElement, index: number) {
        const sponsorElement = settings.sponsors[settings.sponsorIndex];
        const resourcePrevious = settings.resources[index - 1] ? settings.resources[index - 1] : resource;
        const resourceNext = settings.resources[index + 1] ? settings.resources[index + 1] : resource;
        const isLastElement =
            !resource.classList.contains('showcase__fake') &&
            resourceNext.classList.contains('showcase__fake') &&
            settings.sponsorIndex == 0;

        if (resource.style.marginBottom) {
            resource.style.removeProperty('margin-bottom');

            if (resourcePrevious && settings.sponsors[settings.sponsorIndex]) {
                settings.sponsors[settings.sponsorIndex].removeAttribute('style');
            }
        }

        if (
            settings.sponsors[settings.sponsorIndex] &&
            !resourcePrevious.classList.contains('showcase__fake') &&
            sponsorElement.clientHeight > 0
        ) {
            if (resource.offsetTop !== settings.offsetY) {
                settings.rowCount++;
                settings.rowHeight += resourcePrevious.clientHeight + settings.spacingY;

                settings.offsetY = resource.offsetTop;
            }

            if (
                settings.sponsorRow == settings.rowCount &&
                ((0 == settings.sponsorRow && settings.sponsorIndex == 0) || settings.sponsorRow > 0)
            ) {
                const element = 0 == settings.sponsorRow ? settings.row : resourcePrevious;
                const elementStyleMethod = 0 == settings.sponsorRow ? 'paddingTop' : 'marginBottom';
                const elementStyleSpacing = 0 == settings.sponsorRow ? settings.spacingY : settings.spacingY * 2;

                element.style[elementStyleMethod] = `${sponsorElement.clientHeight + elementStyleSpacing}px`;
                sponsorElement.style.top = `${settings.rowHeight}px`;

                settings.offsetY = resource.offsetTop;

                if (0 !== settings.sponsorRow && !sponsorElement.classList.contains('spr--go-premium')) {
                    settings.row.removeAttribute('style');
                }

                settings.rowCount = 0;
                settings.rowHeight += sponsorElement.clientHeight + settings.spacingY;
                settings.sponsorIndex++;
                settings.sponsorRow = settings.showcase.dataset.sprRow || this.getRow(settings);
            } else if (isLastElement) {
                sponsorElement.style.position = 'relative';
            }
        }

        return settings;
    }

    private static eventHandlerWindowResize(this: Window) {
        Advertisement.getInstances().forEach(showcase => showcase.element.dispatchEvent(new Event('resize')));
    }

    public static set(
        showcase: HTMLElement,
        classesRow: string[] = [],
        classesResources: string[] = [],
        classesSponsor: string[] = [],
    ) {
        if (showcase.dataset.layout?.match(/full|columns/gi)) return;

        classesRow.forEach(str =>
            -1 === Advertisement.classesRow.indexOf(str) ? Advertisement.classesRow.push(str) : null,
        );
        classesResources.forEach(str =>
            -1 === Advertisement.classesResources.indexOf(str) ? Advertisement.classesResources.push(str) : null,
        );
        classesSponsor.forEach(str =>
            -1 === Advertisement.classesSponsor.indexOf(str) ? Advertisement.classesSponsor.push(str) : null,
        );

        const row = q(Advertisement.classesRow.join(','), showcase);

        if (row) {
            const resources = qq(Advertisement.classesResources.join(','), showcase);
            const sponsors = qq(Advertisement.classesSponsor.join(','), showcase);

            if (resources.length && sponsors.length) {
                const resourceStyle = window.getComputedStyle(resources[0]);
                const isBannerDetail = sponsors[0].classList.contains('a2b-grid-design');

                let settings: SetAdvertisementSettings = {
                    isDetail: !!(showcase.closest('#modal-detail.active .detail') || showcase.closest('#main .detail')),
                    resources: resources,
                    row: row,
                    rowCount: 0,
                    rowHeight: 0,
                    offsetY: resources[0].offsetTop,
                    spacingY: isBannerDetail
                        ? parseFloat(Settings['large-spacing'])
                        : parseFloat(resourceStyle.marginBottom || Settings['grid-columns-spacing']),
                    sponsorIndex: 0,
                    sponsorRow: 2,
                    sponsors: sponsors,
                    showcase: showcase,
                };

                settings.sponsorRow = showcase.dataset.sprRow || Advertisement.getRow(settings);

                if (!showcase.classList.contains('showcase--spr')) {
                    showcase.classList.add('showcase--spr');
                    showcase.addEventListener('resize', () => Advertisement.set(showcase));
                    Advertisement.instances.push({
                        element: showcase,
                    });
                }

                resources.forEach(
                    (resource, index) => (settings = Advertisement.setPosition(settings, resource, index)),
                );

                sponsors.forEach(sponsor => {
                    if (!sponsor.getAttribute('style')) {
                        sponsor.style.display = 'none !important';
                    }
                });
            }
        }
    }

    public static setup() {
        if (!Advertisement.isSetup) {
            Advertisement.isSetup = true;
            window.addEventListener('resize', Advertisement.eventHandlerWindowResize);
        }
    }
}

Advertisement.setup();

interface SetAdvertisementSettings {
    isDetail: boolean;
    resources: HTMLElement[];
    row: HTMLElement;
    rowCount: number;
    rowHeight: number;
    offsetY: number;
    spacingY: number;
    sponsorIndex: number;
    sponsorRow: number | string;
    sponsors: HTMLElement[];
    showcase: HTMLElement;
}

interface AdvertisementInstance {
    element: HTMLElement;
}
