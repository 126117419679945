/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cookie } from 'bobjoll/ts/library/cookie';
import { delegate, q, qq, ww } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { localStorage } from 'bobjoll/ts/library/storage';
import { setAdsTag } from 'common/sponsor-common/sponsor.helpers';
import { arrayShuffle, numberNormalizer } from 'Library/helpers';
import { isAdBlockEnabled } from 'Library/helpers/adblock';
import Tracker from 'Library/tracker';
import { Advertisement } from 'Partials/advertisement';
import { addNativeAdvertising } from 'Partials/showcase/showcase-native-ad';
import { isPremium } from 'Partials/user/user.helpers';
import pixabay from 'Project/ts/library/flex-images-limit-size';

import { thumbnailElements } from './showcase.thumbnail';
import { MultiResourceDictionary, Resource } from './showcase.vm';
import { addClickableElements, hideElements } from './showcase-tags';

export class Showcase {
    private static readonly Pixabay = pixabay;
    private static readonly PixabaySettings: PixabaySettings = ['maxRows', 'rowHeight', 'truncate'];
    private static readonly PixabayRowHeight = 264;
    private static readonly namespace = 'showcase';
    private static readonly data: (string | { [name: string]: string[] })[] = [
        'categories',
        'creation_ago',
        'creation_date',
        'typology',
        'danger',
        'download_file_url',
        'download_landing_url',
        'download_license_url',
        'downloads',
        'freepik_editor',
        'freepik_stories',
        'id',
        'is_essential',
        'keywords',
        'likes',
        'main_keywords_sponsor',
        'main_keywords_label',
        'main_keywords',
        'new',
        'premium',
        'preview_height',
        'preview_width',
        'original_width',
        'original_height',
        'print_type',
        'selection',
        'title',
        'url_source',
        'ris',
        { author: ['id', 'user_id', 'link', 'name', 'total_resources'] },
        { print_type: ['id', 'value'] },
        { thumbnails: ['mini', 'small', 'large', 'zoom', 'display_sizes'] },
    ];
    private static layout: Layout = 'fixed';
    private static fullWidth = window.innerWidth <= Settings.breakpoints.xs;
    private static timeout: any;
    private static loadVerticalGrid = FEATURE_VERTICAL_GRID_BY_LANGUAGE && !VIEW_TEMPLATE.match('userFollowing');
    public static layoutInstance: LayoutInstanceObject = {};

    private static async initialize() {
        const documentWidth = document.body ? document.body.clientWidth : 0;

        if (document.readyState !== 'complete') {
            await new Promise(resolve => {
                document.addEventListener('DOMContentLoaded', () => resolve(true));
            });

            if (!Showcase.loadVerticalGrid && documentWidth !== document.body.clientWidth) {
                window.dispatchEvent(new Event('resize'));
            }
        }
    }

    private static clearActiveLayoutInstance() {
        Object.keys(Showcase.layoutInstance).forEach(key => {
            const instance = Showcase.layoutInstance[key];

            if (!instance.element.parent('body') || instance.element.dataset.layout !== instance.layout) {
                const destroy = instance.getPathValue('methods.destroy');
                if ('function' === typeof destroy) {
                    destroy();
                }
                delete Showcase.layoutInstance[key];
            }
        });
    }

    private static camelCased(string: string) {
        return string.replace(/_([a-z])/g, group => group[1].toUpperCase());
    }

    private static showLayout(showcase: HTMLElement) {
        showcase.classList.remove('invisible');
    }

    private static setActiveButton(callback?: Function) {
        const wrapper = q('.layout');

        if (wrapper) {
            qq('[data-layout]', wrapper).forEach(button => {
                button.classList[(button.dataset.layout || '') === Showcase.layout ? 'add' : 'remove']('active');
                if (callback) {
                    callback(button);
                }
            });
        }
    }

    private static setLayoutMethod(showcase: HTMLElement) {
        const id = Showcase.getLayoutID(showcase);

        if (showcase.dataset.layout !== Showcase.layout || !showcase.dataset.showcaseId) {
            showcase.dataset.showcaseId = id;

            if ('columns' === showcase.dataset.layout) {
                Showcase.setLayoutMethodColumns(showcase);
            } else {
                if ('true' === showcase.dataset.layoutEditable) {
                    showcase.dataset.layout = Showcase.layout;
                }

                if (Showcase.fullWidth) {
                    Showcase.setLayoutMethodFull(showcase);
                } else {
                    if ('fixed' === showcase.dataset.layout) {
                        Showcase.setLayoutMethodFixed(showcase);
                    }

                    if ('pixabay' === showcase.dataset.layout) {
                        Showcase.setLayoutMethodPixabay(showcase);
                    }

                    if (qq('.spr', showcase).length) {
                        Advertisement.set(showcase);
                    }
                }
            }

            setTimeout(() => showcase.dispatchEvent(new Event('resize')), 50);
        }
    }

    private static setLayoutMethodFull(showcase: HTMLElement) {
        const id = Showcase.getLayoutID(showcase);

        Showcase.layoutInstance[id] = {
            id,
            layout: 'full',
            element: showcase,
        };

        window.addEventListener('resize', Showcase.eventHandlerResize.bind(showcase));
    }

    private static setLayoutMethodColumns(showcase: HTMLElement) {
        Showcase.showLayout(showcase);
    }

    private static setLayoutMethodFixed(showcase: HTMLElement) {
        const id = Showcase.getLayoutID(showcase);

        const instance = (Showcase.layoutInstance[id] = {
            id,
            layout: Showcase.getLayoutType(),
            element: showcase,
            methods: {
                resize: Showcase.eventHandlerResize.bind(showcase),
            },
        });

        Showcase.showLayout(showcase);
        Showcase.setLayoutMethodFixedClass(showcase);
        instance.methods.resize();
        window.addEventListener('resize', instance.methods.resize);
    }

    private static setLayoutMethodPixabay(showcase: HTMLElement) {
        const id = Showcase.getLayoutID(showcase);
        const settings = Showcase.getLayoutSettings(showcase);

        if (settings) {
            Showcase.layoutInstance[id] = {
                id,
                layout: Showcase.getLayoutType(),
                element: showcase,
                methods: new Showcase.Pixabay(settings),
            };

            Showcase.showLayout(showcase);
        }
    }

    private static getDataAttribute(elements: HTMLElement[], name: any, parentName?: string): string {
        const key = Showcase.camelCased(`${parentName ? `${parentName}_` : ''}${name}`);

        return elements.reduce((acc, element) => {
            const value: any = element.dataset[key];

            if ('' === acc && value) {
                acc = value;
            }

            return acc;
        }, <any>'');
    }

    private static getLayoutID(showcase: HTMLElement) {
        const id = `showcase_${new Date().getTime()}`;

        if (showcase.dataset.showcaseId) {
            if ('false' !== showcase.dataset.layoutEditable && Showcase.layout !== showcase.dataset.layout) {
                Showcase.destroy([showcase]);
            }

            return showcase.dataset.showcaseId || id;
        }

        return id;
    }

    private static getLayout(): Layout {
        // remove after several months when users has not any showcase/layout localStorage saved
        localStorage.removeItem(Showcase.namespace, 'layout');
        // remove 20/06/2023
        Cookie.removeItem('5d7c909132b2942298aa4fa00d73b195');
        localStorage.removeItem('session', 'limitpremium');
        return Showcase.getLayoutType();
    }

    private static getLayoutType(): Layout {
        if (Showcase.fullWidth) {
            return 'full';
        }

        return 'pixabay';
    }

    private static getLayoutSettings(
        showcase: HTMLElement,
    ): LayoutMethodPixabaySettings | LayoutMethodFixedSettings | void {
        const layout = Showcase.getLayoutType();

        if ('pixabay' === layout) {
            return Showcase.getLayoutPixabaySettings(showcase);
        }
    }

    private static getLayoutPixabaySettings(showcase: HTMLElement): LayoutMethodPixabaySettings | void {
        const row = q('.row', showcase);

        if (row) {
            const settings: LayoutMethodPixabaySettings = {
                selector: row,
                container: '.showcase__item:not(.hide), .shutterstock__item:not(.hide), .collection__item:not(.hide)',
                rowHeight: Showcase.PixabayRowHeight,
            };

            Showcase.PixabaySettings.forEach(modifier => {
                const dataset = showcase.dataset[modifier];
                if (dataset) {
                    settings[modifier] = dataset;
                }
            });

            return settings;
        }

        console.warn(`The showcase structure is not correct, the row was not found.`, showcase);
    }

    private static eventHandlerResize(this: HTMLElement) {
        Showcase.fullWidth = window.innerWidth <= Settings.breakpoints.xs;

        if ('full' === this.dataset.layout && !Showcase.fullWidth) {
            Showcase.layout = Showcase.getLayout();
            Showcase.setLayout();
        }

        if ('fixed' === this.dataset.layout) {
            Showcase.setLayoutMethodFixedClass(this);
        }
    }

    public static setLayoutMethodFixedClass(showcase: HTMLElement) {
        if (showcase.dataset.layout !== 'fixed' || Showcase.fullWidth) return;

        const row = q('.row', showcase);
        if (row) {
            const children = Array.prototype.slice.call(row.children) as HTMLElement[];

            if (children.length) {
                let rowCount = 0;
                let offsetY = children[0].offsetTop;

                children.forEach((child, index) => {
                    const previous = child.previousElementSibling as HTMLElement | undefined;
                    child.removeAttribute('data-row');

                    if (offsetY !== child.offsetTop) {
                        rowCount++;
                        offsetY = child.offsetTop;

                        if (previous) {
                            previous.dataset.row = rowCount.toString();
                        }
                    } else if (!rowCount && index === children.length - 1) {
                        child.classList.add('last-child');
                    }
                });
            }
        }
    }

    private static eventHandlerClickLayoutButton(this: HTMLButtonElement) {
        const elements = Showcase.getElements();
        if (this.dataset.layout) {
            Showcase.layout = Showcase.getLayoutType();
            Showcase.setActiveButton();
            localStorage.setItem(Showcase.namespace, 'layout', Showcase.layout);
            Tracker.layout(Showcase.layout);
        }
        if (elements.length) {
            Showcase.setLayout({ showcases: elements });
        }
    }

    public static setup() {
        if (!Showcase.loadVerticalGrid) {
            Showcase.layout = Showcase.getLayout();

            window.addEventListener('resize', () => {
                clearTimeout(Showcase.timeout);

                Showcase.timeout = setTimeout(() => {
                    const layout = Showcase.getLayout();
                    const elements = Showcase.getElements(true);

                    if (layout !== Showcase.layout && elements.length) {
                        Showcase.layout = layout;

                        Showcase.setLayout({
                            showcases: elements,
                            layout: layout,
                        });
                    }

                    elements.forEach(showcase => showcase.dispatchEvent(new Event('resize')));
                }, 250);
            });
        }

        Showcase.initialize();
    }

    public static getElements(active?: boolean, parent?: string): HTMLElement[] {
        const wrapper = document || (parent ? (document as Document).querySelector(parent) : document);
        if (active) {
            const showcase: HTMLElement[] = [];
            Object.keys(Showcase.layoutInstance).forEach(key =>
                Showcase.layoutInstance[key].element ? showcase.push(Showcase.layoutInstance[key].element) : null,
            );
            return showcase;
        }
        return qq('.showcase, .shutterstock', wrapper);
    }

    private static setVisibility(showcase: HTMLElement, options?: LayoutOptions) {
        if ((ww.SHOW_SPONSOR != undefined && !ww.SHOW_SPONSOR) || !options || !options.visibilityUpdated) {
            showcase.style.visibility = 'visible';
        }
    }

    public static setLayout(options?: LayoutOptions) {
        const settings: LayoutSettings = {
            showcases: Showcase.getElements(),
            layout: Showcase.layout,
            ...options,
        };

        Showcase.clearActiveLayoutInstance();

        settings.showcases.forEach(showcase => {
            Showcase.setLayoutMethod(showcase);

            if (Showcase.loadVerticalGrid) {
                import('./showcase.vertical-grid').then(({ initializeVerticalGrid }) => {
                    initializeVerticalGrid(showcase, () => Showcase.setVisibility(showcase, options));
                });
            } else {
                Showcase.setVisibility(showcase, options);
            }
        });
    }

    public static setButton() {
        window.onload = () => Showcase.setActiveButton();
        Showcase.setActiveButton();
        delegate('.button[data-layout]', 'click', Showcase.eventHandlerClickLayoutButton);
    }

    public static reOrderBanner(showcase: HTMLElement, banner: HTMLElement, newIndex: number, selector: string) {
        const resourcesList = qq(selector, showcase);
        const elementSelected = resourcesList[newIndex];

        if (elementSelected && elementSelected.parentNode) {
            banner.setAttribute('data-index-changed', newIndex.toString());
            elementSelected.parentNode.insertBefore(banner, elementSelected.nextSibling);
        }
    }

    public static setMulti(showNativeAd: boolean) {
        const ignorePositions = FIXED_RESOURCES_IN_RESULTS;

        Showcase.getElements().forEach(showcase => {
            const resourcesList = qq('.showcase__item[data-multi]', showcase);
            const wepikBanner = q('[data-multi="wepik"]', showcase);
            const resourcesDefault = {
                boost: [],
                latest_boost: [],
                premium: [],
            };

            let latestBoostAdded = 0;
            const resources = resourcesList.reduce((acc: MultiResourceDictionary, resource: HTMLElement) => {
                if (resource.dataset.multi && acc[resource.dataset.multi]) {
                    acc[resource.dataset.multi].push(resource);
                }

                return acc;
            }, resourcesDefault);

            arrayShuffle(resources.latest_boost).forEach(resource => {
                const position = Math.floor(
                    Math.random() * (resources.boost.length - ignorePositions) + ignorePositions,
                );
                const boosted = resources.boost[position];

                if (boosted && boosted.parentNode) {
                    if (latestBoostAdded < resources.latest_boost.length) {
                        boosted.parentNode.insertBefore(resource, boosted);
                        resources.boost.splice(position, 0, resource);

                        latestBoostAdded++;
                    } else {
                        if (resource.parentNode) {
                            resource.parentNode.removeChild(resource);
                        }
                    }
                }
            });

            if (resources.premium.length) {
                resources.premium.forEach(resource => {
                    const position = Math.floor(
                        Math.random() * (resources.boost.length - ignorePositions) + ignorePositions,
                    );
                    const boosted = resources.boost[position];

                    if (boosted && boosted.parentNode) {
                        boosted.parentNode.insertBefore(resource, boosted);
                        resources.boost.splice(position, 0, resource);
                    }
                });
            }

            if (wepikBanner && resourcesList.length) {
                if (window.innerWidth <= Settings.breakpoints.md) {
                    wepikBanner.remove();
                    return;
                }

                const excludeLastPosition = 2;
                const rangeResources = 5;
                const numResources = 25;

                const length = resourcesList.length - excludeLastPosition;
                const range =
                    length > numResources
                        ? [numResources - rangeResources, numResources]
                        : [length - rangeResources, length];

                const index = Math.floor(Math.random() * (range[1] - range[0]) + range[0]);

                Showcase.reOrderBanner(showcase, wepikBanner, index, '.showcase__item[data-multi]');
            }
        });

        this.setSprBanners(showNativeAd);
    }

    private static setSprBanners(showNativeAd: boolean) {
        if (
            !FEATURE_DISABLE_ADS &&
            showNativeAd &&
            !isAdBlockEnabled() &&
            !isPremium() &&
            FEATURE_SHOW_NATIVE_ADVERTISING_BY_LANGUAGE
        ) {
            qq('.spr.spr-adblock').forEach(item => item.remove());
            addNativeAdvertising();
        }

        !Showcase.loadVerticalGrid && setAdsTag('.spr.spr__test-ad');
    }

    public static getData(showcase: HTMLElement, key: string) {
        const response: Resource[] = [];
        const testNumberArr = new RegExp(/^[0-9]+(,[0-9]+)*$/);
        if (showcase) {
            const resources = qq('.js-detail-data', showcase);

            showcase.dataset.cacheKey = key;

            resources.forEach((item, index) => {
                const imageSrc = item.dataset.image;
                const link = q('.js-detail-data-link', item) as HTMLLinkElement | undefined;
                const preview = q('.showcase__link img, .js-detail-data-link img', item) as
                    | HTMLImageElement
                    | undefined;
                const titleElement = q('.title', item) as HTMLElement | undefined;
                const title = preview?.alt || item.dataset.title || titleElement?.innerText || titleElement?.innerHTML;
                const isPremium = q('.icon--premium', item) || q('.icon--crown-filled', item) ? true : false;

                const printType = {
                    id: item.dataset.printTypeId || '',
                    value: item.dataset.printTypeValue || '',
                };

                if (Showcase.loadVerticalGrid || FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY) {
                    thumbnailElements(item);
                } else {
                    hideElements(item);
                }
                addClickableElements(item);

                if (link) {
                    const builder: ResourceBuilder = {};

                    Showcase.data.forEach(attribute => {
                        if ('string' === typeof attribute) {
                            (builder as any)[attribute] = Showcase.getDataAttribute([item, link], attribute);
                        }

                        if ('object' === typeof attribute) {
                            Object.keys(attribute).forEach(key => {
                                if (attribute[key]) {
                                    (builder as any)[key] = attribute[key].reduce((acc, attribute) => {
                                        acc[attribute] = Showcase.getDataAttribute([item, link], attribute, key);

                                        return acc;
                                    }, {} as any);
                                }
                            });
                        }
                    });

                    let keywords: number[] = [];

                    link.dataset.cacheId = index.toString();

                    if (
                        builder.keywords &&
                        'string' === typeof builder.keywords &&
                        testNumberArr.test(builder.keywords)
                    ) {
                        keywords = builder.keywords.split(',').map((x: any) => parseInt(x as string, 10));
                    }

                    const authorAvatarElement = q('.avatar img', item) as HTMLImageElement | undefined;
                    const authorNameElement = q('.name', item);

                    if (builder.author) {
                        builder.author.avatar =
                            item.dataset.authorAvatar ||
                            (authorAvatarElement && (authorAvatarElement.dataset.src || authorAvatarElement.src));

                        if (authorNameElement) {
                            builder.author.name = authorNameElement.innerText || authorNameElement.innerHTML;
                            builder.author.slug = authorNameElement.dataset.authorSlug;
                            builder.author.link = `${AUTHOR_URL}${authorNameElement.dataset.authorSlug ||
                                authorNameElement.innerText}`;
                            builder.author.id = link.dataset.authorId || authorNameElement.dataset.id || '';
                            builder.author.user_id =
                                link.dataset.authorUserId || authorNameElement.dataset.userId || '';
                        } else {
                            builder.author.name = item.dataset.authorName || '';
                            builder.author.slug = item.dataset.authorSlug || '';
                            builder.author.link = `${AUTHOR_URL}${item.dataset.authorSlug || item.dataset.authorName}`;
                            builder.author.id = item.dataset.authorId || '';
                            builder.author.user_id = link.dataset.authorUserId || '';
                        }
                    }

                    if (imageSrc) {
                        if (!builder.thumbnails) {
                            builder.thumbnails = {};
                        }
                        if (preview) {
                            builder.thumbnails['small'] = preview.dataset.src;
                        } else {
                            builder.thumbnails['small'] = imageSrc;
                        }
                        builder.thumbnails['large'] = imageSrc;
                    }

                    if (title) {
                        builder.title = title;
                    }

                    const data: Resource = {
                        ...builder,
                        danger: '1' == builder.danger,
                        freepik_editor: IS_MOBILE ? 0 : builder.freepik_editor,
                        freepik_stories: builder.freepik_stories && !IS_MOBILE,
                        height: item.dataset.h,
                        id: item.dataset.id,
                        is_essential_and_country: item.dataset.isEssential == '1' && ESSENTIAL_COUNTRY == '1',
                        keywords,
                        new: 'true' == builder.new,
                        pixel: item.dataset.pixel,
                        premium: isPremium,
                        preview_height: numberNormalizer(builder.preview_height || '0'),
                        preview_width: numberNormalizer(builder.preview_width || '0'),
                        print_type: printType,
                        ratio: numberNormalizer(builder.ratio || '0'),
                        ris: builder.ris,
                        selection: builder.selection || 0,
                        type: item.dataset.type,
                        url_source: link.href,
                        width: item.dataset.w,
                    };

                    if (item.dataset.date) {
                        data.creation_date = item.dataset.date;
                    }

                    response.push(data);
                }
            });
        }
        return (response as any) as Resource[];
    }

    public static destroy(elements: HTMLElement[]) {
        elements.forEach(showcase => {
            const instance = Showcase.layoutInstance[showcase.dataset.showcaseId || ''];

            if (showcase.dataset.showcaseId && instance) {
                showcase.removeAttribute('data-showcase-id');

                if (instance.methods && instance.methods.destroy) {
                    instance.methods.destroy();
                }

                delete Showcase.layoutInstance[showcase.dataset.showcaseId];
            }
        });
    }
}

Showcase.setup();

type Layout = 'fixed' | 'pixabay' | 'full' | 'columns';
type PixabaySettings = ('maxRows' | 'rowHeight' | 'truncate')[];

interface LayoutSettings {
    layout: string;
    showcases: HTMLElement[];
}

interface LayoutOptions {
    layout?: string;
    showcases?: HTMLElement[];
    visibilityUpdated?: boolean;
}

interface LayoutInstanceObject {
    [name: string]: LayoutInstance;
}

interface LayoutInstance {
    id: string | number;
    layout: string;
    element: HTMLElement;
    methods?: LayoutInstanceMethods;
}

interface LayoutInstanceMethods {
    destroy?(element?: HTMLElement | HTMLElement[]): void;
    resize?(showcase: Element): void;
}

interface LayoutMethodPixabaySettings {
    selector: Element;
    container: string;
    rowHeight: string | number;
    maxRows?: string | number;
    truncate?: string;
    [key: string]: any;
}

interface LayoutMethodFixedSettings {
    [key: string]: any;
}

type ResourceBuilder = any;
