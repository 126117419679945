import 'Variables';

import { __ } from 'Partials/language';
import { sprintf } from 'sprintf-js';

export default {
    E_UNKNOW: __('Something has gone wrong, but the system can not determine the exact cause, please try again later.'),
    E_CHECK_RECAPTCHA: __('The recaptcha has not been verified. Are you a robot?'),
    E_USER_NOT_FOUND: sprintf(
        __('The account or password you entered were invalid. Enter a different account or %sregister now%s'),
        '<button type="button" class="semibold tabs__link underline" data-tab="tab-register">',
        '</button>',
    ),
    E_LOGIN_ATTEMPTS_REACHED: __('Maximum number of attempts reached, please try again later.'),
    E_EMPTY_IDENTITY: __('The username is empty, please enter your username.'),
    E_BANNED_ACCOUNT: sprintf(
        __('The account has been banned. You can contact %ssupport%s if you think this is a mistake.'),
        `<a href="${SUPPORT_URL}">`,
        '</a>',
    ),
    E_DISABLED_ACCOUNT: sprintf(
        __('The account has been disabled. You can contact %ssupport%s if you think this is a mistake.'),
        `<a href="${SUPPORT_URL}">`,
        '</a>',
    ),
    E_EMPTY_PASSWORD: __('The password is empty, please enter your password.'),
    E_WRONG_PASSWORD: sprintf(
        __('The account or password you entered were invalid. Enter a different account or %sregister now%s'),
        '<button type="button" class="semibold tabs__link underline" data-tab="tab-register">',
        '</button>',
    ),
    E_PASSWORD_COMPROMISED: sprintf(
        __('Your password could have been compromised.%sCan we help you change your %spassword%s?'),
        '<br/>',
        `<a class="underline" onclick='document.querySelector("#gr-auth__login-form .tabs__link[data-tab=tab-forgot-password]").click();'>`,
        '</a>',
    ),
    E_SUSPICIOUS_ACTIVITY: sprintf(
        __(
            "Sorry, we are detecting suspicious activity on your account, we can't log you in. If you think it is an error, please contact our %sCustomer Support%s.",
        ),
        `<a href="${BASE_URL}profile/support">`,
        '</a>',
    ),
    // Register
    E_MAIL_EXISTS: __('Another user with this email already exists.'),
    E_USERNAME_EXISTS: __('Another user with this username exists.'),
    E_TWITTERID_EXISTS: __('The twitter account has already been registered.'),
    E_GOOGLEID_EXISTS: __('The google account has already been registered.'),
    E_FACEBOOKID_EXISTS: __('The facebook account has already been registered.'),
    E_INSECURE_PASSWORD: __('The password you are trying to use has already been used.'),
    // Validation
    E_VALIDATION_RULEUNDEFINED: __('The validation rule applied does not exist.'),
    E_VALIDATION_ISDOMAIN: __('The value does not meet the domain format.'),
    E_VALIDATION_ISVALIDIDNUMBER: __('The value does not meet the valid ID format.'),
    E_VALIDATION_ISENDING: __('The value must end with the defined format.'),
    E_VALIDATION_ISSTARTING: __('The value must start with the defined format.'),
    E_VALIDATION_ISNUMERIC: __('The value must be numeric.'),
    E_VALIDATION_ISURL: __('The value must comply with the URL format.'),
    E_VALIDATION_ISEMAIL: __('The value must comply with the email format.'),
    E_VALIDATION_ISINVALIDEMAIL: __('Invalid email address. Try again.'),
    E_VALIDATION_ISSOCIAL: __(
        'The value must contain only alphanumeric characters, periods ".", Hyphens "-, _" or positive sign "+".',
    ),
    E_VALIDATION_ISSOCIAL2: __('The value must be a valid social network url.'),
    E_VALIDATION_ISUSERNAME: __('The value must contain only alphanumeric characters, periods "." And hyphens "-, _"'),
    E_VALIDATION_ISDIFFERENT: __('The value must be different.'),
    E_VALIDATION_ISDIFFERENT_PASSWORD: __('The password must be different from the previous one.'),
    E_VALIDATION_ISEQUAL: __('The value does not match.'),
    E_VALIDATION_ISEQUAL_PASSWORD: __('Passwords must match.'),
    E_VALIDATION_ISSECURE: __(
        'The value must be considered safe. It must include at least: 1 number, 1 capital letter, 1 symbol, and a length between 8 and 30.',
    ),
    E_VALIDATION_ISSECUREPASSWORD: __(
        "The password has to be secure. Be sure it contains at least: 1 number, 1 letter, 1 capital letter, 1 symbol, is between 6 and 30 characters length and doesn't contain whitespaces",
    ),
    E_VALIDATION_ISALPHALOGIN: __('The value must contain only alphanumeric values and underscore "_".'),
    E_VALIDATION_ISALPHA: __('The value must contain only alphanumeric values.'),
    E_VALIDATION_ISMAX: __('The value exceeds the maximum size allowed.'),
    E_VALIDATION_ISMIN: __('The value does not reach the minimum size allowed.'),
    E_VALIDATION_ISREQUIRED: __('The field is mandatory.'),
    E_VALIDATION_ISBETWEEN: __('The field must be between a maximum and a minimum value.'),
};
