import { q, qq } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import template from 'Project/ts/templates/parts/native-ad.hbs';

export const addNativeAdvertising = () => {
    const minResourceForShowAds = 3;

    Showcase.getElements().forEach(showcase => {
        const resourcesNum = qq('.showcase__item', showcase)?.length;

        if (window.innerWidth <= Settings.breakpoints.lg || !resourcesNum || resourcesNum < minResourceForShowAds) {
            removeNativeAds(showcase);
            return;
        }

        positionAds(getTemplateConfig(resourcesNum), resourcesNum, showcase, minResourceForShowAds);
    });
};

const positionAds = (
    templateConfig: AdsTemplateConfig,
    resourcesNum: number,
    showcase: HTMLElement,
    minResourceForShowAds: number,
) => {
    Object.keys(templateConfig).forEach(position => {
        const templateConfigItem = templateConfig[position];

        if (resourcesNum > templateConfigItem.minResources || resourcesNum === minResourceForShowAds) {
            const template = createNativeAds(templateConfigItem.idName);
            const index =
                templateConfigItem?.lastPosition && resourcesNum === minResourceForShowAds
                    ? templateConfigItem.lastPosition
                    : Math.floor(
                          Math.random() * (templateConfigItem.range[1] - templateConfigItem.range[0]) +
                              templateConfigItem.range[0],
                      );
            Showcase.reOrderBanner(showcase, template, index, '.showcase__item');
        }
    });
};

const getTemplateConfig = (resourcesNum: number): AdsTemplateConfig => {
    return {
        first: {
            idName: 'native-ads',
            range: [6, 9],
            lastPosition: resourcesNum - 1,
            minResources: 6,
        },
        second: {
            idName: 'native-ads-second',
            range: [21, 24],
            minResources: 21,
        },
        third: {
            idName: 'native-ads-third',
            range: [46, 49],
            minResources: 46,
        },
    };
};

const createNativeAds = (idName: string) => {
    const adsTemplate = document.createElement('figure');

    adsTemplate.classList.add('spr--native', 'showcase__item');
    adsTemplate.setAttribute('data-multi', idName);
    adsTemplate.setAttribute('data-w', '380');
    adsTemplate.setAttribute('data-h', '230');
    adsTemplate.innerHTML = template();

    return adsTemplate;
};

const removeNativeAds = (showcase: HTMLElement) => q('[data-multi*="native-ads"]', showcase)?.remove();

type AdPositions = 'first' | 'second' | 'third';
type AdsTemplateConfig = {
    [key in AdPositions]: AdConfig;
};

interface AdConfig {
    idName: string;
    range: number[];
    lastPosition?: number;
    minResources: number;
}
