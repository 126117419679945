import { qq } from 'bobjoll/ts/library/dom';

let lazyElementObserver: IntersectionObserver;
let lazyElements: HTMLElement[] = [];
let lazyActiveListeners = false;
let lazyLegactFnActive = false;

if ('IntersectionObserver' in window) {
    lazyElementObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const lazyElement = entry.target as HTMLElement;
                const event = new Event('load');
                lazyElement.dispatchEvent(event);
                lazyElement.remove();
                lazyElementObserver.unobserve(lazyElement);
            }
        });
    });
}

const runObserver = () => lazyElements.forEach(lazyElement => lazyElementObserver.observe(lazyElement));
const runLegacy = () => runLegacyAddListeners();

const runLegacyFn = () => {
    if (lazyLegactFnActive === false) {
        lazyLegactFnActive = true;

        setTimeout(function() {
            lazyElements.forEach(lazyElement => {
                if (
                    lazyElement.getBoundingClientRect().top <= window.innerHeight &&
                    lazyElement.getBoundingClientRect().bottom >= 0 &&
                    getComputedStyle(lazyElement).display !== 'none'
                ) {
                    const event = new Event('load');
                    lazyElement.dispatchEvent(event);
                    lazyElement.remove();
                    lazyElements = lazyElements.filter(element => element !== lazyElement);
                    runLegacyRemoveListeners();
                }
            });

            lazyLegactFnActive = false;
        }, 200);
    }
};

const runLegacyAddListeners = () => {
    if (!lazyActiveListeners) {
        document.addEventListener('scroll', runLegacyFn);
        window.addEventListener('resize', runLegacyFn);
        window.addEventListener('orientationchange', runLegacyFn);
        lazyActiveListeners = true;
    }
};

const runLegacyRemoveListeners = () => {
    if (lazyActiveListeners && lazyElements.length === 0) {
        document.removeEventListener('scroll', runLegacyFn);
        window.removeEventListener('resize', runLegacyFn);
        window.removeEventListener('orientationchange', runLegacyFn);
        lazyActiveListeners = false;
    }
};

export const runScript = (element?: HTMLElement, callback?: Function) => {
    if (element && callback) {
        const scriptLoaderElement = document.createElement('div');
        scriptLoaderElement.onload = () => callback();
        element.appendChild(scriptLoaderElement);
        lazyElements = [scriptLoaderElement];
    } else {
        lazyElements = qq('.lazyload-script');
    }

    lazyElementObserver ? runObserver() : runLegacy();
};
