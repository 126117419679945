import { q,ww } from 'bobjoll/ts/library/dom';

import { imageResize } from '../library/helpers/image';

export const watermark = function(image: HTMLImageElement, error = false) {
    if (error) return;
    const imageLoaded = new Image();
    imageLoaded.src = image.src;
    imageLoaded.onload = () => {
        const watermarkElement = image.parent('.watermark') as HTMLElement | undefined;
        image.classList.add('show');
        if (watermarkElement) {
            const watermarkImageElement = watermarkElement.querySelector('.watermark__image') as
                | HTMLDivElement
                | undefined;
            const imageMinHeight = 640;
            const imageMaxHeight = window.innerHeight * 0.8;
            const imageNewHeight =
                image.naturalHeight > imageMaxHeight
                    ? imageMinHeight > imageMaxHeight
                        ? imageMinHeight
                        : imageMaxHeight
                    : image.naturalHeight;
            const { width, height } = imageResize({
                width: image.naturalWidth,
                height: image.naturalHeight,
                newHeight: imageNewHeight,
            });
            image.setAttribute('width', width.toString());
            image.setAttribute('height', height.toString());
            watermarkElement.style.maxWidth = `${width}px`;
            watermarkElement.style.maxHeight = `${height}px`;
            watermarkElement.classList.add('loaded');
            if (watermarkImageElement) {
                watermarkImageElement.style.paddingBottom = `${(((height - IMG_ZOOM_STRIP) / width) * 100)
                    .toString()
                    .slice(0, 5)}%`;
            }
        }
    };
};

ww.addEventListener('resize', function() {
    const detailThumbElement = (q('#modal-detail.active .detail .watermark__image > img') ||
        q('#main .detail .watermark__image > img')) as HTMLImageElement | undefined;
    if (detailThumbElement) {
        watermark(detailThumbElement);
    }
});

ww.imageLoaded = watermark;
