import { LoadScript } from 'Library/load-scripts';

class GPTScript extends LoadScript {
    constructor() {
        super();
    }

    public addScript() {
        const name = 'gpt-script';

        this.loaded[name] = false;

        this.Scripts = {
            [name]: {
                addScript: `
                    var googletag = googletag || {};
                    googletag.cmd = googletag.cmd || [];

                    var slots = {};
                    var slots_language = 'es' === (LANGUAGE || '') ? '_' + LANGUAGE.toUpperCase() : '';
                    var googletag = googletag || {};
                    googletag.cmd = googletag.cmd || [];

                    if (window.innerWidth >= 640) {
                        googletag.cmd.push(function () {
                            var map_list_1 = googletag.sizeMapping().addSize([0, 0], []).addSize([320, 50], [[320, 50], [320, 100]]).addSize([468, 60], [[320, 50], [320, 100], [468, 60]]).addSize([728, 90], [[468, 60], [728, 90]]).addSize([980, 250], [[728, 90], [970, 90], [970, 250], [980, 90], [980, 250]]).build();
                            var map_item_sidebar_1 = googletag.sizeMapping().addSize([0, 0], []).addSize([300, 50], [[300, 250], 'fluid']).addSize([1081, 250], [[300, 250], [250, 250], 'fluid']).build();

                            slots['LIST_1'] = googletag.defineSlot('/64971086/FREEPIK_LIST_1' + slots_language, [[320, 100], [980, 250], [970, 250], [320, 50], [468, 60], [970, 90], [728, 90], [980, 90]], 'ssm-1455007701531-0').defineSizeMapping(map_list_1).addService(googletag.pubads());
                            slots['LIST_2'] = googletag.defineSlot('/64971086/FREEPIK_LIST_2' + slots_language, [[320, 100], [980, 250], [970, 250], [320, 50], [468, 60], [970, 90], [728, 90], [980, 90]], 'ssm-1455007701531-1').defineSizeMapping(map_list_1).addService(googletag.pubads());
                            slots['LIST_3'] = googletag.defineSlot('/64971086/FREEPIK_LIST_3', [[300, 250], [300, 100], [728, 90], [980, 90], [320, 100], [336, 280], [970, 250], [300, 600], [320, 50], [468, 60], [970, 90]], 'ssm-1550064356826-0').addService(googletag.pubads());
                            slots['SIDEBAR_1'] = googletag.defineSlot('/64971086/FREEPIK_ITEM_336X280_SIDEBAR_1' + slots_language, [[300, 250], [336, 280], [340, 250], 'fluid'], 'ssm-1461149264689-0').defineSizeMapping(map_item_sidebar_1).addService(googletag.pubads());
                            slots['SIDEBAR_2'] = googletag.defineSlot('/64971086/FREEPIK_ITEM_336X280_SIDEBAR_2' + slots_language, [[300, 250], [336, 280], [340, 250], 'fluid'], 'ssm-1509449714283-0').defineSizeMapping(map_item_sidebar_1).addService(googletag.pubads());

                            // Disable initial load, we will use refresh() to fetch ads.
                            // Calling this function means that display() calls just
                            // register the slot as ready, but do not fetch ads for it.
                            googletag.pubads().disableInitialLoad();

                            // Enable services
                            googletag.enableServices();
                        });
                    }

                    if ('function' === typeof ga && 'function' === typeof cookie && 'function' === typeof cookie.setItem) {
                        ga(function (tracker) {
                            var clientId = tracker.get('clientId');
                            cookie.setItem('gr_ga', clientId); // Modificar para usar la librería de establecer cookies de freepik
                        });
                    }`,
                defer: true,
                name,
                url: 'https://www.googletagservices.com/tag/js/gpt.js',
            },
        };

        this.load(name);
    }
}

export const AdsGPTScript = new GPTScript();
