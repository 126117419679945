const SEMSEO_SCRIPTS = {
    pt: 'freepikbr',
    es: 'freepikes',
    de: 'defreepikcom',
    fr: 'frfreepikcom',
    jp: 'jpfreepikcom',
    pl: 'plfreepikcom',
    ru: 'rufreepikcom',
    nl: 'nlfreepikcom',
    it: 'itfreepikcom',
    ko: 'krfreepikcom',
};

const getSemseoScriptByCountry = SEMSEO_SCRIPTS[LANGUAGE] || 'freepik';

export default getSemseoScriptByCountry;
