const THUMBNAIL_VIEW_FALLBACK = 'menu-and-author';
const RESOURCE_WITH_TAGS = {
    'all-actions': 270,
    'three-buttons': 229,
    'menu-buttons': 150,
    'one-row-tags': 112,
};
const RESOURCE_WITHOUT_TAGS = {
    'all-actions': 183,
    'three-buttons': 150,
    'menu-and-author': 108,
};
declare let ResizeObserver: any;
let thumbnailObserver: typeof ResizeObserver;

if (typeof ResizeObserver !== 'undefined') {
    thumbnailObserver = new ResizeObserver((entries: any[]) => {
        for (const entry of entries) {
            const element = entry.target as HTMLElement;
            const height = element.clientHeight;
            handleWidthChange(element, height);
        }
    });
}

const setThumbnailDataset = (item: HTMLElement, classType: string | undefined) => item.dataset.thumbnail = classType || '';

const handleWidthChange = (item: HTMLElement, height: number) => {
    const checkResourceHasTags = item.classList.contains('showcase__item--with-tags');
    const resourceThumbnailType = checkResourceHasTags ? RESOURCE_WITH_TAGS : RESOURCE_WITHOUT_TAGS;

    const classType = Object.keys(resourceThumbnailType).find(
        minHeightToApplyClass => height >= resourceThumbnailType[minHeightToApplyClass],
    );

    setThumbnailDataset(item, classType);
};

export const thumbnailElements = (item: HTMLElement) => {
    if (thumbnailObserver) {
        thumbnailObserver.observe(item, { box: 'border-box' });
    } else {
        setThumbnailDataset(item, THUMBNAIL_VIEW_FALLBACK);
    }
};
