import { LoadScript } from 'Library/load-scripts';

import getSemseoScriptByCountry from './getSemseoScriptByCountry';

class SsmCodesScript extends LoadScript {
    constructor() {
        super();
    }

    public addScript() {
        const name = 'ssmcodes-script';

        this.loaded[name] = false;

        this.Scripts = {
            [name]: {
                async: true,
                name,
                url: `https://ssm.codes/smart-tag/${getSemseoScriptByCountry}.js`,
            },
        };

        this.load(name);
    }
}

export const AdsSsmCodesScript = new SsmCodesScript();
