import Alerts from 'BobjollAlert';
import { __ } from 'Partials/language';
import xhr from 'Partials/xhr';
import { setGrLgUri } from '../partials/user/user.helpers';

export const ontapCallback = (googleUser: any) => {
    setGrLgUri();
    return xhr(`${GIDP_ONE_TAP_LOGIN_URL}&no-redirect=1`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            credential: googleUser.credential,
        },
    })
        .then(response => {
            if (response.success === false || !response.data?.redirectUrl) {
                const alert = new Alerts({ fixed: true, position: 'top-right' });
                alert.new(__(response.message), 'error', true);
            } else {
                window.location.href = response.data.redirectUrl;
            }
        })
        .catch(err => {
            console.log(err);
        });
};
