import { cookie } from 'Library/cookie';
import { sessionDictionary, SessionUser } from './oauth.vm';

function parseData(data: string) {
    try {
        return JSON.parse(data);
    } catch (err) {
        console.warn('user data parse failed', err);
    }
}

export function parseUser(phpStr: string): SessionUser | null {
    if ('string' === typeof phpStr && '' === phpStr) return null;

    const user = parseData(phpStr);

    if (
        user &&
        'id' in user &&
        'login' in user &&
        'email' in user &&
        'avatar' in user &&
        'premium' in user &&
        'cc_will_expire' in user
    ) {
        user.cc_will_expire = user.cc_will_expire == '1';
        user.newsletter = user.newsletter == '1';
        user.premium = user.premium == '1';
        user.premium_flaticon = (user.premium_flaticon as string) == '1';
        user.premium_freepik = (user.premium_freepik as string) == '1';
        user.essential = ESSENTIAL_USER_TYPE === '1';
        user.user_type_fi = user.premium_flaticon ? 'payment-user' : 'registered';

        return user;
    }

    return null;
}

export const getUserData = function(): SessionUser | null {
    const session2 = cookie.getItem('gr_session2');
    if (session2) return parseUser(session2);
    return null;
};

export const mapUserData = (user: string, swap = false): string => {
    const userData = JSON.parse(user);
    const mapData = {};
    Object.keys(sessionDictionary).forEach(key => {
        if (swap) {
            mapData[sessionDictionary[key]] = userData[key];
        } else {
            mapData[key] = userData[sessionDictionary[key]];
        }
    });

    return JSON.stringify(mapData);
};