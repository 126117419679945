import { q } from "bobjoll/ts/library/dom";

import { cookie } from "../library/cookie";

export default function(identity: string, callback?: Function) {
    const modal = document.getElementById('modal-login');

    if (modal) {
        modal.classList.add('newsletter');
        modal.setAttribute('data-disable-mouse-up', '');
    }

    const request = new Promise(resolve => {
        const tabButton = q('.tabs__link[data-tab="tab-subscribe"]');

        if (tabButton) {
            const subscribeNowButton = document.getElementById('subscribe-now');
            const subscribeCancelButton = document.getElementById('subscribe-cancel');

            tabButton.click();

            if (subscribeNowButton && subscribeCancelButton) {
                subscribeCancelButton.addEventListener('click', () => resolve('cancelled'));
                subscribeNowButton.addEventListener('click', () => eventHandlerSubscribe(resolve, identity, subscribeNowButton));
            }
        }
    });

    request.then(function(status: 'cancelled' | 'subscribed') {
        if (modal) {
            modal.classList.remove('newsletter');
            modal.removeAttribute('data-disable-mouse-up');
        }

        cookie.setItem('gr_register_gratitude_status', status);

        if (callback) {
            callback();
        }
    });
}

const eventHandlerSubscribe = function(resolve: any, identity: string, button: HTMLElement) {
    const body = new FormData();

    body.append('allow', '1');
    body.append('identity', identity);
    body.append('option', 'exclusive-resources');

    button.classList.add('button--loading');

    fetch('/profile/request/login/newsletterstrategy', { method: 'POST', body })
        .then(function() {
            button.classList.remove('button--loading');
            resolve('subscribed');
        });
}
