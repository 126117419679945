import { qq } from 'bobjoll/ts/library/dom';
import { cookie } from 'Library/cookie';
import { isMobile } from 'Library/helpers/device';
import { updateParamValue } from 'Library/helpers/url';
import { checkVideos } from 'Project/ts/ui/lazyload-videos';

const BROWSER_SUPPORTS_INTERSECTION_OBSERVER = 'IntersectionObserver' in window;
const excludeImageTypes = ['horizontal', 'vertical'];
const paramSizeName = 'size';
let lazyImageObserver: IntersectionObserver;
let lazyImages: HTMLImageElement[] = [];

const isShowcaseLink = (lazyImage: HTMLImageElement) => {
    const container = (lazyImage.parentNode as HTMLElement) || undefined;
    return (
        container &&
        [].slice
            .call(container.classList)
            .join(',')
            .match(/showcase__link/)
    );
};

if (BROWSER_SUPPORTS_INTERSECTION_OBSERVER) {
    lazyImageObserver = new IntersectionObserver(
        entries => {
            entries.forEach(entry => entry.isIntersecting && handleIntersectionObserverImageEntry(entry));
        },
        { rootMargin: `${LAZY_LOADING_THRESHOLD_PX}px` },
    );
}

const handleIntersectionObserverImageEntry = (entry: IntersectionObserverEntry) => {
    const image = entry.target as HTMLImageElement;
    isShowcaseLink(image) ? updateUserImageSrc(image) : loadImage(image);
    lazyImageObserver.unobserve(image);
};

const getImageSrc = (image: HTMLImageElement) => {
    return image.dataset.src || image.src;
};

const loadImageInLowerResolutionForMobile = (image: HTMLImageElement) => {
    if (isMobile() && image.dataset.orientation && !excludeImageTypes.includes(image.dataset.orientation)) {
        const imgUrl = getImageSrc(image);
        image.dataset.src = updateParamValue(imgUrl, paramSizeName, THUMBNAIL_SIZE_SMALL.toString());
    }
};

export const loadVideos = (parent: HTMLElement) => checkVideos(parent);

export const loadImage = (image: HTMLImageElement) => {
    const src = getImageSrc(image);
    const img = new Image();

    img.onload = function() {
        if (image.dataset.src) {
            image.src = image.dataset.src;
        }
        if (image.dataset.srcset) {
            image.srcset = image.dataset.srcset;
        }

        image.classList.add('loaded');
    };

    img.onerror = img.onabort = () => (image.src = image.dataset.src || '');

    img.src = src;

    image.classList.remove('lzy', 'lazyload');
};

const runObserver = () => lazyImages.forEach(lazyImage => lazyImageObserver.observe(lazyImage));

const deferredLoadImages = () =>
    lazyImages.forEach((image: HTMLImageElement) => {
        isShowcaseLink(image) ? updateUserImageSrc(image) : loadImage(image);
    });

export const updateUserImageSrc = (image: HTMLImageElement) => {
    FEATURE_VERTICAL_GRID_BY_LANGUAGE && loadImageInLowerResolutionForMobile(image);

    const imgUrl = getImageSrc(image);
    const srcset = image.dataset.srcset;

    if (!imgUrl) return;

    const url = getUserSrc(imgUrl);

    image.dataset.src = url;

    if (srcset) {
        const urlSrcset = getUserSrcSet(srcset);
        if (urlSrcset) {
            const completeSrcset = urlSrcset.fullSrcset;
            image.dataset.srcset = completeSrcset;
        }
    }

    loadImage(image);
};

const getUserArgs = (concat: boolean) => {
    const clientId = cookie.getItem('_ga') || '';
    const user = gr.user;

    let userType = '';
    let userId = '';

    if (user) {
        const premium = user.premium || false;
        userType = premium ? 'P' : 'R';
        userId = user.id.toString();
    }

    if (!userType && !clientId) return '';

    const newDatasetSrc: string[] = [];
    const uid = `${userType ? `uid=${userType}${userId}` : ''}`;
    const ga = `${clientId ? `ga=${clientId}` : ''}`;
    const semt = `${TRACKING_SEARCH ? `semt=${TRACKING_SEARCH}` : ''}`;

    uid && newDatasetSrc.push(uid);
    ga && newDatasetSrc.push(ga);
    semt && newDatasetSrc.push(semt);

    newDatasetSrc.join('&');

    return `${concat ? '&' : '?'}${newDatasetSrc.join('&')}`;
};

export const getUserSrc = (src: string) => {
    const concat = src.indexOf('?') > -1;
    const args = getUserArgs(concat);
    return `${src}${args}`;
};

export const getUserSrcSet = (srcset: string): SrcSet => {
    if ('' == srcset) return {};

    const sizesUrl: Sizes[] = [];
    const newDatasetSrcset: string[] = [];

    srcset.split(',').forEach(urlFromSrcset => {
        const src = urlFromSrcset.trim().split(' ');
        if (src) {
            const concat = src[0].indexOf('?') > -1;
            const args = getUserArgs(concat);
            src[0] = `${src[0]}${args}`;

            if (src[0]) {
                const resolution = src[2] ? `${src[1]} ${src[2]}` : src[1] || '';
                const size = {
                    size: resolution,
                    url: src[0],
                };
                sizesUrl.push(size);
            }

            newDatasetSrcset.push(src.join(' '));
        }
    });

    return {
        fullSrcset: newDatasetSrcset.join(','),
        objectSrcset: sizesUrl,
    };
};

export const run = (element?: HTMLElement, degradeToDeferredLoad = false) => {
    const lazySelector = '.lzy, .lazyload';
    lazyImages = (element ? qq(lazySelector, element) : qq(lazySelector)) as HTMLImageElement[];

    if (degradeToDeferredLoad) {
        return deferredLoadImages();
    }

    BROWSER_SUPPORTS_INTERSECTION_OBSERVER ? runObserver() : deferredLoadImages();
};

interface Sizes {
    size: string;
    url: string;
}

interface SrcSet {
    fullSrcset?: string;
    objectSrcset?: Sizes[];
}
