import { localStorage as storage } from 'bobjoll/ts/library/storage';
import { getQueryStringFromURL } from 'Library/helpers/url';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';

const urlTypes: UrlType[] = [
    {
        event: 'sign_up_ok',
        localStorageValue: 'signUpOK',
        urlParam: 'sign-up',
        urlValue: 'email',
    },
    {
        event: 'sign_up_verified_account',
        localStorageValue: 'verifyEmail',
        location: '/sign-up',
        urlParam: 'verify-email',
        urlValue: 'email',
    },
    {
        event: 'login_ok',
        localStorageValue: 'logIn',
        urlParam: 'log-in',
        urlValue: 'email',
    },
    {
        event: 'password_updated',
        localStorageValue: 'updatePassword',
        urlParam: 'update-password',
        urlValue: 'email',
    },
];

const NAMESPACE = 'authGA4';

export const afterLoginTracker = () => {
    const logged = gr && gr.user;
    if (!logged) {
        clearAuthStorage();

        return;
    }
    const url = new URL(window.location.href);
    const urlLoginType = urlTypes.find(urlType => url.searchParams.get(urlType.urlParam));
    if (!urlLoginType) return;
    if (!FEATURE_GA4_EVENTS_FROM_BACKEND) {
        if (storage.getItem(NAMESPACE, urlLoginType.localStorageValue)) return;
        const pageLocation = urlLoginType.location
            ? `${urlLoginType.location}${url.searchParams.get(urlLoginType.urlParam) === 'email' ? '/mail' : ''}`
            : url.toString();

        triggerManualGA4Events({
            description: 'afterLoginEvents',
            options: {
                client_id: 'freepik',
                event: 'ga4event',
                event_name: urlLoginType.event,
                original_client_id: getOriginalClientId(),
                page_location: pageLocation,
                user_id: gr.user.id,
                user_type: gr.user.premium ? 'payment-user' : 'registered',
            },
        });

        storage.setItem(NAMESPACE, urlLoginType.localStorageValue, urlLoginType.urlParam);
    }

    if (FEATURE_USER_PERSONA_MODAL_BY_LANGUAGE && localStorage.getItem('rb-freepik-modal') !== null) {
        (async () => {
            const { userPersonaInitialize } = await import('Partials/user-persona/user-persona.initialize');
            userPersonaInitialize();
        })();
    }
};

export const clearAuthStorage = () => {
    storage.getAllKeys(NAMESPACE).forEach(key => {
        storage.removeItem('', key);
    });
};

export const getOriginalClientId = () => {
    const clientId = getQueryStringFromURL('fp-ga');
    if (clientId) {
        return clientId.split('GA1.1.')[1] || 'nn';
    }

    return 'nn';
};

interface UrlType {
    event: string;
    localStorageValue: string;
    location?: string;
    urlParam: string;
    urlValue: string;
}
