/* eslint-disable @typescript-eslint/camelcase */
export function gtagReportConvertion(url: Location) {
    const callback = function() {
        if (typeof url != 'undefined') {
            window.location = url;
        }
    };
    gtag('event', 'conversion', {
        send_to: 'AW-845664086/k285CLGRtLoBENaen5MD',
        event_callback: callback,
    });
    return false;
}
