import { LoadLink } from 'Library/load-links';

class GPT extends LoadLink {
    constructor() {
        super();
    }

    public addLink() {
        const name = 'gpt';

        this.loaded[name] = false;

        this.Links = {
            [name]: {
                as: 'script',
                href: 'https://www.googletagservices.com/tag/js/gpt.js',
                rel: 'preload',
            },
        };

        this.load(name);
    }
}

export const AdsGPT = new GPT();
