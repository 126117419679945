export const sessionDictionary: GrSession2Dictionary = {
    a: 'avatar',
    cce: 'cc_will_expire',
    d: 'downloads',
    dr: 'download_limit_renew',
    e: 'email',
    fbfi: 'first_buy_fr',
    id: 'id',
    l: 'login',
    ld: 'limit_downloads',
    nl: 'newsletter',
    pffi: 'pr_freq_fi',
    pffp: 'pr_freq_fp',
    pfi: 'premium_flaticon',
    pfp: 'premium_freepik',
    pfpu: 'ultimate',
    pm: 'premium',
    rcfi: 'renewal_canceled_flaticon',
    rcfp: 'renewal_canceled_freepik',
    rcpp: 'renewal_canceled_plus',
    rn: 'real_name',
    ug: 'user_age',
    utfi: 'user_type_fi',
    utfr: 'user_type_fr',
    pmt: 'premiumtest',
    pch: 'purchases',
    w: 'wallet_id',
};

export const userStorageItems: [string, string][] = [
    ['notification', 'lastUpdate'],
    ['notification', 'storedMessages'],
    ['session', 'xhr/device-manager/identify'],
    ['session', 'user'],
    ['session', 'xhr/user/pause-status'],
    ['session', 'xhr/user/last-failed-payment'],
    ['tooltipFixed', 'monthly-limit-finish'],
    ['tooltipFixed', 'monthly-limit'],
];

export interface SessionUser {
    avatar: string;
    cc_will_expire: boolean;
    cc_will_expiry: boolean;
    connected_facebook?: boolean;
    connected_google?: boolean;
    connected_twitter?: boolean;
    downloads?: number;
    download_limit_renew?: number;
    email: string;
    essential: boolean;
    first_buy_fi: any;
    first_buy_fr: any;
    first_buy: any;
    id: number;
    limit_downloads?: number;
    login: string;
    newsletter: any;
    premium_flaticon: boolean;
    premium_freepik: boolean;
    premium: boolean;
    ultimate: boolean;
    profession: string;
    user_age: any;
    user_type_fi: any;
    user_type_fr: any;
    premiumtest: boolean;
    purchases: any;
    wallet_id?: string;
}

export interface StorageUser {
    value: string;
    expiry: number;
}

interface GrSession2Data {
    a: string;
    cce: boolean;
    cd?: Date;
    d: number;
    dr?: number;
    e: string;
    fbfi: any;
    fbfp?: any;
    id: number;
    l: string;
    ld: number;
    nl: any;
    pf?: string;
    pffi?: any;
    pffp?: any;
    pfi: boolean;
    pfp: boolean;
    pfpu: boolean;
    pm: boolean;
    rcfi: boolean;
    rcfp: boolean;
    rcpp: boolean;
    rn: string;
    ts?: number;
    ug: any;
    uh?: string;
    utfi: string;
    utfr: string;
    pmt: boolean;
    pch: any;
    w?: string;
}

export type StorageData = {
    [key in keyof GrSession2Data]?: any;
};

type GrSession2Dictionary = {
    [key in keyof GrSession2Data]: string;
};
