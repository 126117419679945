import { isTablet } from 'Library/helpers/device';

export const checkVideos = (parent: HTMLElement | Document) => {
    const lazyVideos = [].slice.call(parent.querySelectorAll('video.lazyvideo:not(.lazyload--done)'));

    try {
        if ('IntersectionObserver' in window) {
            const lazyVideoObserver = new IntersectionObserver(entries => {
                entries.forEach(function(elementVideo) {
                    if (elementVideo.isIntersecting) {
                        for (const source in elementVideo.target.children) {
                            const videoSource = elementVideo.target.children[source] as HTMLSourceElement;
                            if ('SOURCE' === videoSource.tagName && 'undefined' !== typeof videoSource.dataset.src) {
                                videoSource.src = videoSource.dataset.src;
                            }
                        }

                        const video = elementVideo.target as HTMLVideoElement;

                        if (video) {
                            video.load();

                            (video.parentElement as HTMLElement).addEventListener('mouseover', () => {
                                if (isTablet()) {
                                    video.pause();
                                    video.currentTime = 0;
                                } else {
                                    video.play().catch(() => {
                                        video.load();
                                    });
                                }
                            });

                            (video.parentElement as HTMLElement).addEventListener('mouseleave', () => {
                                video.pause();
                                video.currentTime = 0;
                            });

                            video.classList.add('lazyload--done');
                            lazyVideoObserver.unobserve(video);
                        }
                    }
                });
            });

            if (lazyVideos) {
                lazyVideos.forEach((lazyVideo: HTMLVideoElement) => {
                    lazyVideoObserver.observe(lazyVideo);
                });
            }
        }
    } catch (err) {
        console.error('Error when try to load video', err);
    }
};
