import { q, qq } from 'bobjoll/ts/library/dom';

import triggerManualGA4Events from '../events/ga4.init-manual-events';

declare let ResizeObserver: any;
const visibleButtons = 180;
const tagSize = 36;
const iconSize = 28;
const padding = 14;

export const addClickableElements = (showcase: HTMLElement) => {
    const clickableElements = qq('.tags-links-thumbnails a', showcase);

    if (clickableElements.length === 0) return;

    clickableElements.forEach((ele: HTMLAnchorElement) =>
        ele.addEventListener('click', e => {
            e.stopPropagation();
            triggerManualGA4Events({
                description: 'tagsThumbnails',
                options: {
                    link_text: ele.textContent?.trim() ?? '',
                    link_url: ele.href,
                    file_id: showcase?.dataset.id ?? '',
                },
            });
        }),
    );
};

export const hideElements = (showcase: HTMLElement) => {
    hideButtons(showcase);
    if (qq('.tags-links-thumbnails a', showcase).length == 0) return;
    hideTab(showcase);
    hideIconTabletMobile(showcase);
};

const hideButtons = (showcase: HTMLElement) => {
    const isVertical = showcase.clientWidth < visibleButtons;
    const isHorizontal = showcase.clientHeight < visibleButtons;

    showcase.classList[isVertical ? 'add' : 'remove']('hide-buttons');

    if (!q('.tags-container .tag-item', showcase)) return;

    showcase.classList[isHorizontal ? 'add' : 'remove']('padding-buttons');
};

const hideIconTabletMobile = (showcase: HTMLElement) => {
    if (!q('.active-tags-tablet-mobile .icon--premium ~ .tags-container', showcase)) return;

    const containerTagsSize = q('.tags-container', showcase) as HTMLElement;
    showcase.classList[containerTagsSize.clientHeight + iconSize + padding > showcase.clientHeight ? 'add' : 'remove'](
        'hide-icon-tablet-mobile',
    );
};

const hideTab = (showcase: HTMLElement) => {
    if (!q('.active-tags-tablet-mobile .tags-container', showcase)) return;

    const classHide = 'hide-tag';
    const iconPremiumSize = q('.active-tags-tablet-mobile .icon--premium ~ .tags-container', showcase) ? iconSize : 0;
    const containerTags = q('.tags-container', showcase) as HTMLElement;
    const currentTagsSize = containerTags.clientHeight + iconPremiumSize;
    const twoTagsSize = tagSize * 2 + 5;
    const threeTagsSize = currentTagsSize + tagSize + padding + 5;

    if (showcase.classList.contains(classHide) && showcase.clientHeight > threeTagsSize) {
        showcase.classList.remove(classHide);
        return;
    }

    if (
        !showcase.classList.contains(classHide) &&
        currentTagsSize + padding > showcase.clientHeight &&
        showcase.clientHeight > twoTagsSize
    ) {
        showcase.classList.add(classHide);
    }
};
