import { LoadLink } from 'Library/load-links';

import getSemseoScriptByCountry from './getSemseoScriptByCountry';

class SsmCodes extends LoadLink {
    constructor() {
        super();
    }

    public addLink() {
        const name = 'ssmcodes-link';

        this.loaded[name] = false;

        this.Links = {
            [name]: {
                as: 'script',
                header: true,
                href: `https://ssm.codes/smart-tag/${getSemseoScriptByCountry}.js`,
                rel: 'preload',
            },
        };

        this.load(name);
    }
}

export const AdsSsmCodes = new SsmCodes();
