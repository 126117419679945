import { q, qq } from 'bobjoll/ts/library/dom';
import { KEvent, KEventTarget } from 'bobjoll/ts/library/event';
import { localStorage, sessionStorage } from 'bobjoll/ts/library/storage';
import View from 'BobjollView';
import delay from 'Library/delay';
import { getRandomRange, numberNormalizer } from 'Library/helpers';
import { triggerWindowResize } from 'Library/helpers/resize';
import { afterLoginTracker, clearAuthStorage } from 'Partials/events/ga4.login';
import { __ } from 'Partials/language';
import tracker from 'Partials/tracker';
import { gtm } from 'Partials/tracker-gtm';
import { setGrLgUri } from 'Partials/user/user.helpers';
import { pixelScriptPinterest } from 'Project/ts/library/pixels/pixel.pinterest';

import { mapUserData, parseUser } from './helpers';
import { SessionUser, StorageData, StorageUser, userStorageItems } from './oauth.vm';
import { gtagReportConvertion } from './tracker';
import { cookie } from 'Library/cookie';

declare let grecaptcha: any;

export class Session extends KEventTarget {
    private static readonly defaultFormNames = ['login', 'register', 'forgot-password'];
    private static readonly defaultSettings = {
        recaptcha: true,
    };
    private static instance: Session;
    private static settings: Settings;
    private static handlers: Handlers = {
        loginHandler: null,
        registerHandler: null,
        forgotPasswordHandler: null,
    };
    private static recaptchaWidgets: Widgets = {
        login: null,
        register: null,
        'forgot-password': null,
    };
    private static recaptachaDisableHanlder = false;
    private static updateInterval: number | undefined;
    private static expiryStorage = 3600000;
    private static updateUserInterval = getRandomRange(10000, 15000);
    private static freeLimit = FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY
        ? DOWNLOAD_LIMIT_FREE_MONTHLY
        : DOWNLOAD_LIMIT_FREE;
    private static premiumLimit = 100;
    public static errorCodes: ResponseErrors;

    public user: SessionUser | null;

    [key: string]: any;

    constructor(Settings: Options) {
        if (!Session.instance) {
            super();
            Session.instance = this;
            Session.settings = { ...Session.defaultSettings, ...Settings };

            if (Session.settings.errorCodes) {
                Session.errorCodes = Session.settings.errorCodes;
            }

            const userData = this.getStorageUserData();

            this.upgradeToPremiumUser(userData);

            this.addEventListener('gr:logout', () => {
                if (Session.updateInterval) {
                    clearInterval(Session.updateInterval);
                    Session.updateInterval = 0;
                }
            });

            this.addEventListener('gr:update:user', () => {
                this.updateUI();
                this.updateBodyClasses();
            });

            if (!this.user) {
                this.removeUserLocalStorage();
                clearAuthStorage();
            }
        }

        return Session.instance;
    }

    addEventListener(type: 'gr:login', listener: (ev: KEventLogin) => any, useCapture?: boolean): any;
    addEventListener(type: 'gr:login-remember', listener: (ev: KEventLoginRemember) => any, useCapture?: boolean): any;
    addEventListener(type: 'gr:ready', listener: (ev: KEventReady) => any, useCapture?: boolean): any;
    addEventListener(type: 'gr:register', listener: (ev: KEventRegister) => any, useCapture?: boolean): void;
    addEventListener(type: 'gr:logout', listener: (ev: KEventLogout) => any, useCapture?: boolean): void;
    addEventListener(type: 'gr:login-error', listener: (ev: KEvent) => any, useCapture?: boolean): void;
    addEventListener(type: 'gr:register-error', listener: (ev: KEvent) => any, useCapture?: boolean): void;
    addEventListener(type: 'gr:forgot-password', listener: (ev: KEvent) => any, useCapture?: boolean): void;
    addEventListener(type: 'gr:forgot-password-error', listener: (ev: KEvent) => any, useCapture?: boolean): void;
    addEventListener(type: 'gr:update:user', listener: (ev: KEventUpdateUser) => any, useCapture?: boolean): void;
    addEventListener(type: string, listener: (ev: KEvent) => any, useCapture = true): void {
        super.addEventListener(type, listener, useCapture);
    }

    private static formHandlerStart(form: HTMLFormElement) {
        const submit = q('[type="submit"]', form);

        if (submit) {
            submit.classList.add('button--loading');
        }

        form.classList.add('disabled');
    }

    private static formHandlerEnd(form: HTMLFormElement, name: string, ...args: any[]) {
        const submit = q('[type="submit"]', form);
        const widget = Session.recaptchaWidgets[name];

        form.classList.remove('disabled');

        if (submit) {
            submit.classList.remove('button--loading');
        }

        if (Session.settings.recaptcha && widget) {
            grecaptcha.reset(widget);
        }

        if (args[0] && args[0] instanceof Event) {
            args[0].preventDefault();
        }
    }

    private async loginHandler(form: HTMLFormElement, ...args: any[]) {
        const data = new FormData(form);

        Session.formHandlerStart(form);

        await Session.instance
            .loginAndRegisterHandler('login', data)
            .then(user => {
                hideMessage(form);
                if (user) {
                    localStorage.setItem('session', 'status', '1');
                    Session.instance.user = user;
                    Session.instance.updateUI();
                    Session.instance.triggerLogin(user);

                    triggerWindowResize();
                }
            })
            .catch(error => {
                showMessage(form, error, 'error');
                Session.instance.triggerError('login');

                if ('number' !== typeof Session.recaptchaWidgets['login']) {
                    const submit = q('[type="submit"]', form);
                    const submitID = `gr-auth__login-submit`;
                    const submitHandlerName = `${Session.dashToCamelCase('login')}Handler`;
                    if (submit) {
                        submit.id = submitID;

                        Session.recaptchaWidgets['login'] = grecaptcha.render(submitID, {
                            sitekey: Session.settings.recaptchaSitekey,
                            callback: Session.handlers[submitHandlerName],
                        });
                    }
                } else {
                    grecaptcha.reset(Session.recaptchaWidgets['login']);
                }
            });

        Session.formHandlerEnd(form, 'login', ...args);
    }

    public async registerHandler(form: HTMLFormElement, ...args: any[]) {
        const data = new FormData(form);
        const reload: boolean = form.dataset.reload ? 'true' === form.dataset.reload : true;

        Session.formHandlerStart(form);

        try {
            const response = (await Session.instance.loginAndRegisterHandler('register', data)) as any;
            // eslint-disable-next-line @typescript-eslint/camelcase
            const { register_social = 'not-social' } = response.data || {};

            hideMessage(form);

            Session.registerCompleteTracker(register_social);

            await delay(250);

            if (
                response &&
                response.data &&
                response.data.display_newsletterstrategy &&
                response.data.identity &&
                Session.settings.newsletterCallback
            ) {
                Session.settings.newsletterCallback(response.data.identity, () =>
                    Session.instance.registerCompleteHandler(data, reload),
                );
            } else {
                Session.instance.registerCompleteHandler(data, reload);
            }
        } catch (error) {
            console.log('error', error);

            gtm(
                {
                    event: 'registered',
                    registerStatus: 'ko',
                },
                false,
            );

            showMessage(form, error, 'error');

            Session.instance.triggerError('register');

            if ('number' === typeof Session.recaptchaWidgets['register']) {
                grecaptcha.reset(Session.recaptchaWidgets['register']);
            }
        }

        Session.formHandlerEnd(form, 'register', ...args);
    }

    private async registerCompleteHandler(data: FormData, reload?: boolean) {
        const social = ['google_id', 'twitter_id', 'facebook_id'].filter(network =>
            data.get(network) ? '0' != data.get(network) : false,
        );
        Session.instance.triggerRegister(data);
        Session.registerCompleteTracker((social.pop() || 'not-social').replace('_id', ''));
        await Session.registerDependenciesLoaded();
        if (-1 != social.indexOf('twitter_id')) {
            const twitterLibrary = () => import('bobjoll/ts/library/gr/social/twitter');
            const Twitter = await twitterLibrary();
            const twitterData = new FormData();
            twitterData.append('twitter_id', data.get('twitter_id') || '');
            return Twitter.default.auth('login', data);
        }
        Session.registerCompleteLoginHander(
            (data.get('username') || '') as string,
            (data.get('password') || '') as string,
        );

        pixelScriptPinterest.addEvent('signup');

        if (reload) {
            window.location.reload();
        }
    }

    private static registerCompleteLoginHander(username: string, password: string) {
        const form = q('.gr-auth__login-form') as HTMLFormElement | undefined;

        if (form) {
            const usernameElement = q('input[name="username"]', form) as HTMLInputElement | undefined;
            const passwordElement = q('input[name="password"]', form) as HTMLInputElement | undefined;

            if (usernameElement && passwordElement) {
                const widget = Session.recaptchaWidgets.login;

                usernameElement.value = username;
                passwordElement.value = password;

                if ('number' === typeof widget && grecaptcha) {
                    grecaptcha.execute(widget);
                } else {
                    Session.instance.loginHandler(form);
                }
            }
        }
    }

    private static registerCompleteTracker(network = 'not-social') {
        try {
            gtm({
                event: 'modalLogin',
                addToUrl: 'registered',
            });
            gtm({
                event: 'registered',
                registerStatus: 'ok',
                registerType: network,
                eventCallback: () => window.dispatchEvent(new Event('gr:register:event')),
            });
            gtagReportConvertion(window.location);
            tracker('send', 'event', {
                category: 'user',
                action: 'registered',
                label: 'not-social',
            });
        } catch (err) {
            console.error('Error', err);
        }
    }

    public async forgotPasswordHandler(form: HTMLFormElement, ...args: any[]) {
        const data = new FormData(form);

        Session.formHandlerStart(form);

        await Session.instance
            .forgotPasswordRequestHandler(data)
            .then((errorData: MessageResponse) => {
                hideMessage(form);
                showMessage(form, { message: errorData.message }, errorData.status || 'success');
            })
            .catch(error => {
                showMessage(form, error, 'error');

                Session.instance.triggerError('login');

                if ('number' === typeof Session.recaptchaWidgets['forgot-password']) {
                    grecaptcha.reset(Session.recaptchaWidgets['forgot-password']);
                }
            });

        Session.formHandlerEnd(form, 'forgot-password', ...args);
    }

    private forgotPasswordRequestHandler(formData: FormData) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();

            req.addEventListener('load', () => {
                try {
                    const ret = JSON.parse(req.response);

                    if (req.status !== 200) {
                        reject({
                            message: ret.data.message || ret.data.errors,
                            error_code: ret.data.error_code || 'E_UNKNOW',
                        });
                    }

                    resolve({
                        message: ret.data.message,
                        status: ret.data.status ? 'success' : 'error',
                    });
                } catch (e) {
                    console.error('Error reading response (json):', req.response);
                    reject('Error reading response (json):' + req.response);
                }
            });

            // TODO: Extract this URL from the form.action
            req.open('POST', '/profile/request/login/lost_password');
            req.setRequestHeader('X-Requested-With', 'xmlhttprequest');
            req.send(formData);
        });
    }

    private loginAndRegisterHandler(action: 'login' | 'register', formData: FormData) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            const attempts = numberNormalizer(sessionStorage.get('session', 'login') || '0');

            req.addEventListener('load', () => {
                try {
                    if (req.status === 200) {
                        const ret: LoginResponse = JSON.parse(req.response);

                        if (ret.data.status) {
                            if (ret.data.userdata) {
                                resolve(ret.data.userdata);
                            } else {
                                resolve(ret as any);
                            }
                        } else {
                            if ('login' === action) {
                                sessionStorage.set('session', 'login', attempts + 1);
                            }

                            reject({
                                message: ret.data.message || ret.data.errors,
                                error_code: ret.data.error_code || 'E_UNKNOW',
                            });
                        }
                    } else if (req.status >= 400 && req.status <= 500) {
                        reject({
                            message: __(
                                'You have reached the max requests allowed. Please try again in a few minutes.',
                            ),
                        });
                    } else {
                        if ('login' === action) {
                            sessionStorage.set('session', 'login', attempts + 1);
                        }

                        reject('Error in user login (' + req.status + '): ' + req.response);
                    }
                } catch (e) {
                    reject(req.response);
                }
            });

            // TODO: Extract this URL from the form.action
            req.open('POST', action == 'login' ? '/profile/request/login' : '/profile/request/login/register');
            req.setRequestHeader('X-Requested-With', 'xmlhttprequest');
            req.send(formData);
        }) as Promise<SessionUser | undefined>;
    }

    private upgradeToPremiumUser(userData: StorageUser | undefined) {
        let force = false;

        if (this.isOAuthLogged() && userData) {
            try {
                const userDataJSON = JSON.parse(userData.value);
                const isPremiumUserInLocalStorage = userDataJSON && userDataJSON['pfp'];
                force = isPremiumUserInLocalStorage !== IS_PREMIUM_USER;
            } catch (err) {
                console.warn('user data update failed', err);
                this.updateUser(true);
            }
        }

        this.updateUser(force);
    }

    private static socialLoginAndRegisterHandler(action: 'login' | 'register' | 'connect', data: FormData) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();

            req.addEventListener('load', () => {
                try {
                    if (req.status === 200) {
                        const ret: SocialResponse = JSON.parse(req.response);

                        if (ret.data.status) {
                            resolve(ret);
                        } else {
                            reject(ret.data.message || ret.data.errors);
                        }
                    } else if (405 === req.status) {
                        reject(
                            __(
                                `We have detected unusual use of our service from the device or network that you're connected to. If you are not a bot, you can continue browsing in a few minutes.`,
                            ),
                        );
                    } else {
                        reject('Error in user login (' + req.status + '): ' + req.response);
                    }
                } catch (e) {
                    console.error('Error reading response (json):', req.response);
                    reject('Error reading response (json):' + req.response);
                }
            });

            req.open(
                'POST',
                action == 'login'
                    ? '/profile/request/login/social_login'
                    : action == 'register'
                    ? '/profile/request/login/register'
                    : '/profile/request/profile/connect',
            );
            req.setRequestHeader('X-Requested-With', 'xmlhttprequest');
            req.send(data);
        }) as Promise<SocialResponse | undefined>;
    }

    private static dashToCamelCase(name: string) {
        return name.replace(/-([a-z])/g, function(g) {
            return g[1].toUpperCase();
        });
    }

    private static loadRecaptcha() {
        let js,
            fjs = document.getElementsByTagName('script')[0];

        js = document.createElement('script') as HTMLScriptElement;

        js.id = 'recaptcha-sdk';
        js.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit';

        if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);

        return new Promise((resolve, reject) => {
            (window as any).recaptchaLoaded = resolve;

            setTimeout(() => reject('Failed loading recaptcha!'), 10000);
        }).catch(err => console.error(err));
    }

    private static registerDependenciesLoaded() {
        return new Promise(resolve => {
            if (google_tag_manager) {
                window.addEventListener('gr:register:event', resolve);
                setTimeout(resolve, 2000);
            } else {
                resolve();
            }
        });
    }

    public async socialAuthHandler(action: 'login' | 'register' | 'connect', data: FormData) {
        const response = await Session.socialLoginAndRegisterHandler(action, data).catch(error => {
            const formElement = q('.tabs__link--heading.active + .tabs__content form');
            if (formElement) {
                let message = '';

                if ('string' === typeof error) {
                    message = error;
                } else if ('object' === typeof error && error.email && 'object' === typeof error.email) {
                    message = error.email[Object.keys(error.email)[0]];
                }

                showMessage(
                    formElement,
                    {
                        message,
                    },
                    'error',
                );
            }
        });

        if (response) {
            if (response.data && (response.data.register_social || '').match(/google|facebook/)) {
                await Session.instance.registerCompleteHandler(data);
            }

            if (response.data && response.data.status && response.data.status === true) {
                Session.instance.updateUser();
                Session.instance.updateUI();

                if (Session.instance.user) {
                    Session.instance.triggerLogin(Session.instance.user);
                }
            }
        }

        return response;
    }

    public async addEventListeners() {
        if (Session.settings.recaptcha) {
            await Session.loadRecaptcha();
        }

        Session.defaultFormNames.forEach(name => {
            const formClass = `.gr-auth__${name}-form`;
            const submitHandlerName = `${Session.dashToCamelCase(name)}Handler`;
            const submitHandlerObject = this[submitHandlerName];

            if (formClass && submitHandlerObject) {
                qq(formClass).forEach((form, index) => {
                    Session.handlers[submitHandlerName] = (event: Event) => {
                        if (event instanceof Event) {
                            event.preventDefault();
                        }

                        if (Session.recaptachaDisableHanlder) {
                            Session.recaptachaDisableHanlder = false;
                            return;
                        }

                        submitHandlerObject(form, event);
                    };

                    if (Session.settings.recaptcha) {
                        const submit = q('[type="submit"]', form);
                        const submitID = `gr-auth__${submitHandlerName}-submit-${index}`;

                        if (submit && typeof Session.recaptchaWidgets[name] !== 'number') {
                            submit.id = submitID;
                            Session.recaptchaWidgets[name] = grecaptcha.render(submitID, {
                                sitekey: Session.settings.recaptchaSitekey,
                                callback: Session.handlers[submitHandlerName],
                                'error-callback': () => grecaptcha.reset(),
                            });
                        }
                    } else {
                        form.addEventListener('submit', Session.handlers[submitHandlerName] as EventListener);
                    }
                });
            }
        });
    }

    public removeEventListeners() {}

    public triggerError(type: string) {
        return this.dispatchEvent(KEvent.fromType(`gr:${type}-error`));
    }

    public triggerLogin(user: SessionUser) {
        return this.dispatchEvent(new KEventLogin(user));
    }

    public triggerLoginRemember(user: SessionUser) {
        return this.dispatchEvent(new KEventLoginRemember(user));
    }

    public triggerReady() {
        return this.dispatchEvent(new KEventReady());
    }

    public triggerRegister(data: FormData) {
        return this.dispatchEvent(new KEventRegister(data));
    }

    public triggerLogout() {
        return this.dispatchEvent(new KEventLogout());
    }

    public triggerUpdateUser() {
        return this.dispatchEvent(new KEventUpdateUser());
    }

    public updateUI() {
        const user = this.user;

        if (user) {
            qq('.gr-auth__name').forEach(element => (element.innerHTML = user.login));
            qq('.gr-auth__email').forEach(element => (element.innerHTML = user.email));
            qq('.gr-auth__avatar').forEach((image: HTMLImageElement) => {
                if (!(image instanceof HTMLImageElement)) return;

                image.src = user.avatar || Session.settings.defaultAvatar;
                image.alt = user.login;

                image.onerror = () => (image.src = Session.settings.defaultAvatar);
            });

            const downloadCounter = q('#icons_downloaded_counters');
            if (
                downloadCounter &&
                typeof user.downloads !== 'undefined' &&
                typeof user.limit_downloads !== 'undefined'
            ) {
                const limit = this.downloadLimitUser(user.limit_downloads);
                downloadCounter.innerHTML = user.downloads + '/' + limit;
            }

            if (!Session.updateInterval) {
                Session.updateInterval = window.setInterval(() => {
                    this.updateUser();
                    this.updateUI();
                }, Session.updateUserInterval);
            }
        }

        qq('.gr-auth').forEach(element => {
            element.classList.remove(
                'gr-auth--logged',
                'gr-auth--not-logged',
                'gr-auth--premium',
                'gr-auth--not-premium',
                'gr-auth--essential',
                'gr-auth--not-essential',
                'gr-auth--ultimate',
                'gr-auth--not-ultimate',
            );

            element.classList.add(`gr-auth--${this.isLogged() ? '' : 'not-'}logged`);
            element.classList.add(`gr-auth--${this.isPremium() ? '' : 'not-'}premium`);
            element.classList.add(`gr-auth--${this.isEssential() ? '' : 'not-'}essential`);
        });

        qq('.gr-auth__logout-button').forEach(element => {
            if ('__clickToLogout' in element) return;

            (element as any)['__clickToLogout'] = true;

            element.addEventListener('click', (event: Event) => {
                this.doLogout();
                event.preventDefault();
            });
        });
    }

    public updateBodyClasses() {
        document.body.classList[gr.isPremium() ? 'add' : 'remove']('premium-user');
        document.body.classList[gr.isEssential() ? 'add' : 'remove']('essential-user');

        if (gr.user && gr.user.email.match(/\@freepik.com/)) {
            document.body.classList.add('admin-user');
        }
    }

    public doLogout() {
        this.removeUserLocalStorage();

        cookie.getItem('notify--pause-subscription-banner-top')
        && cookie.removeItem('notify--pause-subscription-banner-top')

        setGrLgUri();
        window.location.href = LOGOUT_URL;
    }

    public updateUser(force = false) {
        if (this.isOAuthLogged()) {
            const current = new Date().getTime();
            const userData = this.getStorageUserData();

            if (userData && userData.expiry > current && !force) {
                const mapData = mapUserData(userData.value, true);
                this.user = parseUser(mapData);
            } else {
                const lastUpdated = localStorage.getItem('session', 'user-updated') || '0';
                if (current - parseInt(lastUpdated) >= Session.updateUserInterval) {
                    localStorage.setItem('session', 'user-updated', current.toString());
                    this.getXHRUserData()
                        .then(user => {
                            const userData = JSON.stringify(user);
                            this.user = parseUser(userData);

                            const mapData = mapUserData(userData);
                            const storage: StorageUser = {
                                value: mapData,
                                expiry: current + Session.expiryStorage,
                            };

                            localStorage.setItem('session', 'user', JSON.stringify(storage));
                            this.triggerUpdateUser();
                            afterLoginTracker();
                        })
                        .catch(() => this.doLogout());
                }
            }
        } else {
            const grSessionTxt2 = decodeURIComponent(
                document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*gr_session2\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1'),
            );
            this.user = parseUser(grSessionTxt2);
        }

        if (this.user && this.user.id) {
            localStorage.setItem('session', 'status', '1');
        }
    }

    public updateTodayDownloads(downloads: number) {
        const data: StorageData = {
            d: downloads,
        };

        if (USER_TYPE === 'free' && !this.isWinner()) {
            data.ld = Session.freeLimit;
        }

        this.updateStorageUserData(data);
    }

    public checkUserLimitAndRenewal() {
        if (!this.user || USER_TYPE !== 'free' || this.isWinner()) return;

        if (FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY) {
            const userRenewal = this.user.download_limit_renew;

            if (userRenewal) {
                const current = new Date();
                const renewal = new Date(userRenewal * 1000);

                if (current > renewal) {
                    this.updateUser(true);
                    return;
                }
            } else {
                this.updateUser(true);
                return;
            }
        }

        const userLimit = this.user.limit_downloads;

        if (typeof userLimit !== 'undefined' && userLimit != Session.freeLimit) {
            this.updateStorageUserData({
                ld: Session.freeLimit,
            });
        }
    }

    public isLogged() {
        return !!this.user || this.isOAuthLogged();
    }

    public isPremium() {
        return this.user && this.user.premium;
    }

    public isUltimate() {
        return this.user && this.user.ultimate;
    }

    public isEssential() {
        return this.user && this.user.essential;
    }

    public isOAuthLogged() {
        return LOGGED_BY_OAUTH;
    }

    private downloadLimitUser(limit: number) {
        const almostInfinite = 10000000;
        return limit > almostInfinite ? '∞' : limit;
    }

    private getStorageUserData() {
        const storage = localStorage.getItem('session', 'user');
        let userData;

        if (storage) {
            try {
                userData = JSON.parse(storage) as StorageUser;
            } catch (err) {
                console.warn('user data parse failed', err);
            }
        }

        return userData;
    }

    private updateStorageUserData(data: StorageData) {
        const storage = this.getStorageUserData();

        if (storage) {
            try {
                const userData = JSON.parse(storage.value);

                Object.keys(data).forEach(key => {
                    userData[key] = data[key];
                });

                storage.value = JSON.stringify(userData);
                const mapData = mapUserData(storage.value, true);
                this.user = parseUser(mapData);

                localStorage.setItem('session', 'user', JSON.stringify(storage));
                this.triggerUpdateUser();
            } catch (err) {
                console.warn('user data update failed', err);
                this.updateUser(true);
            }
        } else {
            this.updateUser(true);
        }
    }

    private getXHRUserData() {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.addEventListener('load', () => {
                try {
                    if (request.status === 200) {
                        const response = JSON.parse(request.response);
                        if (response.success) {
                            resolve(response.data);
                        } else {
                            reject('Not logged');
                        }
                    } else {
                        reject('Error status');
                    }
                } catch (e) {
                    reject('Error reading response: ' + request.response);
                    throw e;
                }
            });
            request.open('GET', '/xhr/logged-user-data');
            request.setRequestHeader('X-Requested-With', 'xmlhttprequest');
            request.send();
        });
    }

    private isWinner() {
        return USER_TYPE === 'free' && this.user && this.user.limit_downloads === Session.premiumLimit;
    }

    private removeUserLocalStorage() {
        localStorage.setItem('session', 'status', '0');

        if (localStorage.getItem('session', 'user')) {
            userStorageItems.forEach((item: [string, string]) => localStorage.removeItem(item[0], item[1]));
            localStorage.removeItem('session', 'user-updated');
        }
    }
}

interface Settings {
    defaultAvatar: string;
    recaptcha: boolean;
    recaptchaSitekey: string;
    registerCompleteHandler?: Function;
    errorCodes?: ResponseErrors;
    newsletterCallback?: (identity: string, callback?: Function) => void;
}

interface Options {
    defaultAvatar: string;
    recaptcha?: boolean;
    recaptchaSitekey: string;
    registerCompleteHandler?: Function;
    errorCodes?: ResponseErrors;
    newsletterCallback?: (identity: string, callback?: Function) => void;
}

interface Handlers {
    loginHandler: null | FormHandlers;
    registerHandler: null | FormHandlers;
    forgotPasswordHandler: null | FormHandlers;
    [key: string]: null | FormHandlers;
}

interface Widgets {
    login: null | string;
    register: null | string;
    'forgot-password': null | string;
    [key: string]: null | string;
}

class KEventLogin extends KEvent {
    constructor(user: SessionUser) {
        super();
        this.type = 'gr:login';
        this.extra = { user: user };
    }
}

class KEventLoginRemember extends KEvent {
    constructor(user: SessionUser) {
        super();
        this.type = 'gr:login-remember';
        this.extra = { user: user };
    }
}

class KEventReady extends KEvent {
    constructor() {
        super();
        this.type = 'gr:ready';
    }
}

class KEventRegister extends KEvent {
    constructor(data: FormData) {
        super();
        this.type = 'gr:register';
        this.extra = data;
    }
}

export class KEventLogout extends KEvent {
    constructor() {
        super();
        this.type = 'gr:logout';
    }
}

export class KEventUpdateUser extends KEvent {
    constructor() {
        super();
        this.type = 'gr:update:user';
    }
}

interface LoginResponse {
    data: {
        status: boolean;
        redirect_url: string;
        error_code: string;
        message?: string | string[];
        post_callback: string;
        identity?: string;
        rememberme?: boolean;
        userdata?: SessionUser | false;
        errors?: RegisterError | string[] | string;
    };

    callback: string;
}

interface ResponseErrors {
    [key: string]: string;
    E_UNKNOW: string;
    E_CHECK_RECAPTCHA: string;
    E_USER_NOT_FOUND: string;
    E_LOGIN_ATTEMPTS_REACHED: string;
    E_EMPTY_IDENTITY: string;
    E_BANNED_ACCOUNT: string;
    E_DISABLED_ACCOUNT: string;
    E_EMPTY_PASSWORD: string;
    E_WRONG_PASSWORD: string;
    E_PASSWORD_COMPROMISED: string;
    E_SUSPICIOUS_ACTIVITY: string;
    E_VALIDATION_RULEUNDEFINED: string;
    E_VALIDATION_ISDOMAIN: string;
    E_VALIDATION_ISVALIDIDNUMBER: string;
    E_VALIDATION_ISENDING: string;
    E_VALIDATION_ISSTARTING: string;
    E_VALIDATION_ISNUMERIC: string;
    E_VALIDATION_ISURL: string;
    E_VALIDATION_ISEMAIL: string;
    E_VALIDATION_ISINVALIDEMAIL: string;
    E_VALIDATION_ISSOCIAL: string;
    E_VALIDATION_ISSOCIAL2: string;
    E_VALIDATION_ISUSERNAME: string;
    E_VALIDATION_ISDIFFERENT: string;
    E_VALIDATION_ISDIFFERENT_PASSWORD: string;
    E_VALIDATION_ISEQUAL: string;
    E_VALIDATION_ISEQUAL_PASSWORD: string;
    E_VALIDATION_ISSECURE: string;
    E_VALIDATION_ISSECUREPASSWORD: string;
    E_VALIDATION_ISALPHALOGIN: string;
    E_VALIDATION_ISALPHA: string;
    E_VALIDATION_ISMAX: string;
    E_VALIDATION_ISMIN: string;
    E_VALIDATION_ISREQUIRED: string;
    E_VALIDATION_ISBETWEEN: string;
}

interface SocialResponse {
    callback: string;
    data: {
        message: string;
        post_callback: string;
        register_social?: string;
        redirect_url: string;
        status: boolean;
        errors: string[];
    };
}

type FormHandlers = (this: HTMLElement, event: Event) => void;

type RegisterError =
    | { username: string[] }
    | { email: string[] }
    | { facebook_id: string[] }
    | { google_id: string[] }
    | { twitter_id: string[] };
interface ResponseError {
    message: string | string[] | RegisterError;
    error_code?: string | { code: string; field: string }[];
}

export async function showMessage(form: HTMLElement, error: ResponseError, type: 'error' | 'success') {
    const messageBlock = q('.message', form);
    const { default: messageTemplate } = await import(`BobjollTemplate/alert-v1.0/element.html.hbs`);

    const messagesProfile = error.message;
    let message = error.message;

    qq('.notification--error', form).forEach(notification => {
        if (notification.parentElement) {
            notification.parentElement.removeChild(notification);
        }
    });

    if (Session.errorCodes && error.error_code) {
        const errorMessages = qq('.message--field', form);

        if (errorMessages) {
            errorMessages.forEach(errorField => {
                if (errorField.parentElement) {
                    errorField.parentElement.removeChild(errorField);
                }
            });
        }

        try {
            if ('string' === typeof error.error_code && Session.errorCodes[error.error_code]) {
                message = Session.errorCodes[error.error_code];
            }

            if (Array.isArray(error.error_code)) {
                error.error_code.forEach(error => {
                    const field = q(`input[name="${error.field}"]`, form);

                    if (field) {
                        const group = field.parent('.group');
                        const insertElement = group ? group : field;

                        insertElement.insertAdjacentHTML(
                            'afterend',
                            View.render(messageTemplate, {
                                class: `notification--${type} notification--static animation--fade-in`,
                                html: `${
                                    Session.errorCodes[error.code]
                                        ? Session.errorCodes[error.code]
                                        : messagesProfile[error.field][error.code]
                                }`,
                            }),
                        );
                    }
                });

                return;
            }
        } catch (err) {}
    }

    if (!messageBlock) return;

    if (message instanceof Array) {
        messageBlock.innerHTML = message
            .map(html =>
                View.render(messageTemplate, {
                    class: `notification--${type} notification--static animation--fade-in`,
                    html: html,
                }),
            )
            .join('');
    } else if (typeof message === 'string') {
        messageBlock.innerHTML = View.render(messageTemplate, {
            class: `notification--${type} notification--static animation--fade-in`,
            html: message,
        });
    } else {
        let err: string[] = [];

        for (const field in message) {
            err = err.concat((message as { [key: string]: any[] })[field]);
            const input = (q('input[name=' + field + ']', form) as HTMLInputElement) || document.getElementById(field);
            if (input) {
                const group = <HTMLElement | undefined>input.parent('.group');

                if (group && 1 === qq('input', group).length) {
                    group.classList.add('error');
                } else {
                    input.classList.add('error');
                }
            }
        }

        messageBlock.innerHTML = err
            .map(field => {
                const html = 'string' === typeof field ? field : (field as any).getPathValue('messages');

                return View.render(messageTemplate, {
                    class: `notification--${type} notification--static animation--fade-in`,
                    html: html,
                });
            })
            .join('');
    }

    if (messageBlock) {
        messageBlock.classList.add(type);
        messageBlock.style.display = 'block';
    }
}

export function hideMessage(form: HTMLElement) {
    const messageBlock = q('.message', form);
    if (!messageBlock) return;
    messageBlock.innerHTML = '';
    messageBlock.style.display = 'none';
    messageBlock.classList.remove('error', 'success');
    for (const input of qq('input', form)) {
        input.classList.remove('error', 'success');
    }
}

export interface AuthLogoutResponse {
    callback: string;
    data: {
        cross_logout: string[];
        message: string;
        post_callback: string;
        redirect_url: string;
        status: boolean;
    };
}

interface MessageResponse {
    message: string | string[];
    status?: 'error' | 'success';
    error_code?: string;
}
