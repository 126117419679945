import 'Variables';

export class LoadLink {
    public Links: Links;
    public loaded: LinkLoaded = {};

    public async load(name: string) {
        const linkAs = this.Links[name].as;
        const linkCrossOrigin = this.Links[name].crossorigin;
        const linkInHeader = this.Links[name].header;
        const linkHref = this.Links[name].href;
        const linkRel = this.Links[name].rel;

        const linksDOM = document.getElementsByTagName('link');
        const linkFiles = linkInHeader
            ? document.querySelector('link[hreflang="x-default"]')
            : linksDOM[linksDOM.length - 1];

        if (linkHref && linkFiles) {
            const link = document.createElement('link') as HTMLLinkElement;
            link.id = `load-link-${name}`;
            if (linkAs) link.as = linkAs;
            if (linkCrossOrigin) link.setAttribute('crossorigin', '');
            if (linkRel) link.rel = linkRel;
            link.href = linkHref;

            if (linkFiles.parentNode) linkFiles.parentNode.insertBefore(link, linkFiles);
        }

        this.loaded[name] = true;
    }
}

interface Links {
    [name: string]: Link;
}

interface Link {
    as?: string;
    crossorigin?: boolean;
    header?: boolean;
    href: string;
    rel?: string;
}

interface LinkLoaded {
    [key: string]: boolean;
}
